import React from 'react'
import styled from 'styled-components/macro'
import stars from '../../assets/stars.png'
import banner from '../../assets/banner.png'
import heart from '../../assets/heart.png'
import { useTranslation } from 'react-i18next'


const Section = styled.section`
width: 100%;
margin: 0 auto;
justify-content: center;
align-items: center;
overflow-y: hidden;
overflow-x: hidden;
display: flex;
flex-direction: column;
`

const BannerMain = styled.div`
display: block;
width: 100%;
position: relative;
display: flex;
justify-content: center;
align-items: center;
z-index: 1;

img{
    margin: 0rem 50%;
    width: 360px;
    height: 547px;
}

@media (max-width: 577px){
img{    
    width: 290px;
    height: 467px;
    margin-left: 50%;
}
}
`
const Stars = styled.div`
position: absolute;
top: 0;
left: 0rem;
img{
    width: 900px;
    height: 600px;
}

@media (max-width: 577px){
    img{
        width: 100%;
        height: 100%;
    }
}
`
const TextBlock = styled.div`
position: absolute;
top: 40%;
height: 150px;
width: 1120px;

@media (max-width: 577px){
    width: 100%;
    top: 40%;

}
`

const BannerTextHead = styled.h1`
position: relative;
width: 800px;
font-size: 48px;
font-family: 'Palatino';
font-weight: bold;
color: rgba(254, 97, 3, 0.35);
left: 2rem;
top: -2rem;

@media (max-width: 577px){
    font-size: 27px;
    width: 200px;
    left: 1rem;
    top: -8rem;
}

`

const BannerTextRight = styled.h3`
    position: relative;
    justify-content: space-between;
    width: 300px;
    height: 32px;
    font-family: 'Palatino';
    font-weight: 400;
    font-size: 30px;
    color: rgba(154, 114, 114, 0.8);
    left: 65%;
    top: -1.5rem;

    img{
        width: 13px;
        height: 13px;
        position: relative;
        top: -56px;
        left: 83px
    }

    @media (max-width: 577px){
        font-size: 17px;
        top: -120px;
        left: 59%;
        width: fit-content;

        img{
            top: -1.5rem;
            left: 60px;
        }
}
`

const BannerTextLeft = styled.h3`
position: relative;
width: 800px;
font-family: 'Palatino';
font-weight: 400;
font-size: 30px;
left: 14rem;
color: rgba(154, 114, 114, 0.8);
right: 0;
top: 1rem;

@media (max-width: 577px){
font-size: 17px;
left: 1rem;
width: 100px;

}
`

const ShowRowWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 28px;
    background: rgb(255,255,255);
    background: linear-gradient(100deg, rgba(255,255,255,1) 10%, rgba(233, 71, 81, 0.4) 23%, rgba(233, 71, 81, 0.4) 30%, rgba(111, 197, 255, 1) 51%, rgba(233, 71, 81, 0.4) 70%, rgba(233, 71, 81, 0.4) 77%, rgba(255,255,255,1) 90%);
    text-decoration: underline;
    text-shadow: 0px 4px 20px #FFF;
    cursor: pointer;
    color: #F5F5F5;

    a {
        text-decoration: none;
        font-family: 'Montserrat';
        line-height: 42px;
        font-weight: 800;
        font-size: 16px;
        color: #F5F5F5;
        display: block;
        position: relative;
    }

    span {
        position: absolute;
        font-size: 17px;
        bottom: 2px;
    }

    @media (max-width: 517px){
        background: linear-gradient(0.25turn,rgba(233, 71, 81, 0.4), rgba(111, 197, 255, 1), rgba(233, 71, 81, 0.4));

        a {
            line-height: 13px;
            font-size: 13px;
        }
    }
`

const Banner = () => {
    const { t } = useTranslation()
  return (
    <Section>
        {/* <ShowRowWrapper>
            <a href='/show'>{t('PromoShow')}</a> 
        </ShowRowWrapper> */}
        <BannerMain>
            <img src = {banner} alt="banner" />
            <Stars>
                <img src={stars} alt="stars" />
            </Stars>
            <TextBlock>
                <BannerTextHead>Create with love</BannerTextHead>
                <BannerTextLeft>teddy and dolls</BannerTextLeft>
                <BannerTextRight>
                    you can find here
                    <img src={heart} alt="heart" />
                </BannerTextRight>
            </TextBlock>
        </BannerMain>
    </Section>
  )
}

export default Banner