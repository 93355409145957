import { useTranslation } from 'react-i18next'
import {Root, BannerBear, MainText, MainTextTitle, GreetingTitle, 
    GreetingTitleMobile, WinnersTitle, WinnersIcon, TestGradText, WinnerText, 
    WinnersTitleMobile, TestGradTextSecond, WinnersSubTitleMobile
} from '../styles';
import {MarketPart} from './../online-show-works/styles';
import winIcon from './assets/win-icon.png'
import naturalWinner from './assets/natural-look.png'
import fantasyWinner from './assets/fantasy-look.png'
import originalWinner from './assets/original-winner.png'
import adorableWinner from './assets/adorable-winner.png'
import creativeWinner from './assets/creative-winner.png'
import choiceTooBears1 from './assets/from_toobears1.png'
import choiceFromKelly from './assets/from_Kelly.png'

import WinnerCard from './winner-card/winner-card';


const OnlineShowFinish = () => {
    const { t } = useTranslation();

    return (
        <Root>
            <BannerBear></BannerBear>
            <GreetingTitle>{t('FinishShow')}</GreetingTitle>
            <GreetingTitleMobile>{t('FinishShow')}</GreetingTitleMobile>
            <MainText>
                <MainTextTitle><i>{t('ToobearShow')} -</i> </MainTextTitle>
                {t('ToobearShowDesc')}
            </MainText>
            <MainText>{t('ShowDesc2')}</MainText>
            <WinnersTitle>
                <TestGradText>
                    {t('WinnerShowTitle1')}<br/>
                    <TestGradTextSecond>{t('WinnerShowTitle2')}</TestGradTextSecond>
                </TestGradText>
            </WinnersTitle>
            <WinnersTitleMobile>
                <TestGradText>{t('WinnerShowTitleMobile1')}</TestGradText>
                <WinnersSubTitleMobile>{t('WinnerShowTitleMobile2')}</WinnersSubTitleMobile>
                <WinnersSubTitleMobile>{t('WinnerShowTitleMobile3')}</WinnersSubTitleMobile>
            </WinnersTitleMobile>
                <MarketPart>
                    <WinnerCard
                        nomination={<>The most natural <WinnersIcon alt='win' src={winIcon}/> <br/>look</>}
                        img={naturalWinner}
                        award={t('prizeExclusive')}
                        name="Rudolph"
                        author="Natalya Vasileva"
                        authorLink="/Arleana_family"
                        cardLink="/card/67388d0162f2a9ea3b268e62"
                        nominationMobile={<>The most natural look <WinnersIcon alt='win' src={winIcon}/></>}
                    />
                    <WinnerCard
                        nomination={<>The most fantasy <WinnersIcon alt='win' src={winIcon}/> <br/>look</>}
                        img={fantasyWinner}
                        award={t('prizeExclusive')}
                        name="Teddy bear Arkhip"
                        author="Irina Drozdova"
                        authorLink="/6525243e377136f2e27e1be9"
                        cardLink="card/673829fc377d2c6a07444617"
                        nominationMobile={<>The most fantasy look<WinnersIcon alt='win' src={winIcon}/></>}
                    />
                     <WinnerCard
                        nomination={<>The most original <WinnersIcon alt='win' src={winIcon}/> <br/>composition</>}
                        img={originalWinner}
                        award={t('prizeExclusive')}
                        name="Santa's Sleighs"
                        author="Elena Burgova"
                        authorLink="/CrazyZooHandmde"
                        cardLink="/card/6738ae17a1760202aff99186"
                        nominationMobile={<>The most original <WinnersIcon alt='win' src={winIcon}/> <br/>composition</>}
                    />
                    <WinnerCard
                        nomination={<>The most adorable bear<WinnersIcon alt='win' src={winIcon}/> <br/>of the show</>}
                        award={t('prizeExclusive')}
                        img={adorableWinner}
                        name="Martin"
                        author="Iuliia Tikhomirova"
                        authorLink="/Fairydreams"
                        cardLink="/card/6738333a377d2c6a074454ec"
                        nominationMobile={<>The most adorable bear<WinnersIcon alt='win' src={winIcon}/> <br/>of the show</>}
                    />
                    <WinnerCard
                        nomination={<>The most creative <WinnersIcon alt='win' src={winIcon}/> <br/>look</>}
                        img={creativeWinner}
                        award={t('prizeExclusive')}
                        name="Santa Claus"
                        author="Elena Burgova"
                        authorLink="/CrazyZooHandmde"
                        cardLink="/card/6738a4708cc2008d3e069181"
                        nominationMobile={<>The most creative look <WinnersIcon alt='win' src={winIcon}/></>}
                    />
                    <WinnerCard
                        nomination={<>Toobears Choise from a <WinnersIcon alt='win' src={winIcon}/> <br/>Toobears</>}
                        img={choiceTooBears1}
                        award={<>{t('prizeNextShow')}<br/> {t('prizeNextShow2')}</>}
                        name="Epos and bullfinch"
                        author="Ekaterina Medvedeva"
                        authorLink="/MedvedKa"
                        cardLink="/card/67382e19377d2c6a07444bbc"
                        nominationMobile={<>Toobears Choise from a<WinnersIcon alt='win' src={winIcon}/> <br/>Toobears</>}
                    />
                        <WinnerCard
                        nomination={<>Toobears Choise from <WinnersIcon alt='win' src={winIcon}/> <br/>sponsor Nattie Kelly</>}
                        img={choiceTooBears1}
                        award={t('prizSetForSew')}
                        name="Epos and bullfinch"
                        author="Ekaterina Medvedeva"
                        authorLink="/MedvedKa"
                        cardLink="/card/67382e19377d2c6a07444bbc"
                        nominationMobile={<>Toobears Choise from<WinnersIcon alt='win' src={winIcon}/><br/>sponsor Nattie Kelly</>}
                    />
                    <WinnerCard
                        nomination={<>Toobears Choise from a<WinnersIcon alt='win' src={winIcon}/> <br/>jury Alika George</>}
                        img={fantasyWinner}
                        award={<>{t('prizeOffline1')}<br/> {t('prizeOffline2')}</>}
                        name="Teddy bear Arkhip"
                        author="Irina Drozdova"
                        authorLink="/6525243e377136f2e27e1be9"
                        cardLink="card/673829fc377d2c6a07444617"
                        nominationMobile={<>Toobears Choise from a<WinnersIcon alt='win' src={winIcon}/><br/>jury Alika George</>}
                    />
                    <WinnerCard
                        nomination={<>Toobears Choise from <WinnersIcon alt='win' src={winIcon}/> <br/>sponsor Nattie Kelly</>}
                        img={choiceFromKelly}
                        award={t('prizeBook')}
                        name="Bunny Cruise"
                        author="Ekaterina Belova"
                        authorLink="/EkaterinaBelova"
                        cardLink="/card/6738317f377d2c6a074452f1"
                        nominationMobile={<>Toobears Choise from<WinnersIcon alt='win' src={winIcon}/><br/>sponsor Nattie Kelly</>}
                    />
                </MarketPart>
                <WinnerText>
                    {t('WinnerText')}
                </WinnerText>
        </Root>
    )
}

export default OnlineShowFinish;